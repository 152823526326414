// import 'vite/modulepreload-polyfill';
import "../css/main.css";
import "./modules/removeIframeScrollbar.js";
import lazyLoadModule, { loadFile } from "./utils/loaders/moduleLoader.js";

// LAZYLOAD images
lazyLoadModule("lazyLoadImages", 0).then(() => {});

// lazyLoadModule("table-filter", 0).then(() => {});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  // HMR code
  import.meta.hot.accept();
}

lazyLoadModule("alpineComponents", 7000).then(() => {
  window.iFrameResize({ log: true, scrolling: false }, "#formsiFrame");
});

lazyLoadModule("gtm", 7000, { gtmID: window.gtmID }).then(() => {});

// vue components
if (document.querySelector("[data-vue-app-id='algolia-search']")) {
  import(/* webpackChunkName: "algolia-search" */ "./modules/algolia-search.js").then((search) => {
    search.init();
  });
}

// vue components
if (document.querySelector("[data-vue-app-id=product-filter]")) {
  import(/* webpackChunkName: "productFilter" */ "./modules/productFilter.js").then((productFilter) => {
    productFilter.default.init("product-filter");
  });
}
